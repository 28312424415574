import React from 'react';
import { Box, Container } from '@mui/material';

function CenteredContainer({ children }) {
  return (
    <Box
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#f0f0f0', // Light gray background
      }}
    >
      <Container
        maxWidth="lg"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {children}
      </Container>
    </Box>
  );
}

export default CenteredContainer;