import React, { useRef, useState, useEffect } from 'react';
import { Box, Paper, Typography, Container, Divider, Button, Snackbar, Alert, LinearProgress, ButtonGroup } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useAuth } from 'react-oidc-context';
import { styled } from '@mui/material/styles';
import HatFlagCanvas from './HatFlagCanvas';
import BannerCanvas from './BannerCanvas';
import Loading from './Loading';
import PropTypes from 'prop-types';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import RestartAltRoundedIcon from '@mui/icons-material/RestartAltRounded';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';


const LabelItem = styled(Box)(({ theme }) => ({
    backgroundColor: "#8b0e2e",
    padding: 0.5 * theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.info.contrastText,
    borderRadius: 4,
    // Additional styling for nested Typography
    '& .MuiTypography-root': {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

const ImageItem = styled(Paper)(({ theme }) => ({
    justifyContent: 'center',
    display: 'flex',
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(0.5),
    textAlign: 'left',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));

const ItemTypography = styled(({ noWrap = true, ...other }) => (
    <Typography noWrap={noWrap} {...other} />
))(({ theme }) => ({
    // Add your custom styles here
    // e.g., color: theme.palette.primary.main,
}));

function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {`${Math.round(props.value)}%`}
          </Typography>
        </Box>
      </Box>
    );
  }
  
  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };


function Farbenspiel() {
    useEffect(() => {
        document.title = 'Farbenspiel'
    }, []);
    const auth = useAuth(); // for sending requests to the backend

    const [detailsVisible, setDetailsVisible] = useState({});

    const [totalEntries, setTotalEntries] = useState(0);
    const [currentEntry, setCurrentEntry] = useState(null);  // React state for current entry
    const gameState = useRef([]);  // UseRef for game state tracking
    const loading = useRef(true); // could also be a state if I want loading animation
    const gameFinished = useRef(false);
    const [progress, setProgress] = React.useState(0);

    useEffect(() => {
        async function fetchTotalEntries() {
            if (auth.isAuthenticated) {
                try {
                    const token = auth.user?.access_token;
                    const response = await fetch(`${process.env.REACT_APP_API_URI}/farbenspiel/num_items`, {
                        method: "GET",
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });
                    const data = await response.json();
                    // todo: handle response data if it is not a number (perhaps an error message from FastAPI)
                    if (data > 0) {
                        setTotalEntries(data);
                        const firstEntryIndex = Math.floor(Math.random() * data);
                        gameState.current = Array.from({ length: data }, (_, i) => i + 1); // Initialize gameState
                        gameState.current = gameState.current.filter((val, _) => val !== firstEntryIndex); // Remove the first entry from the array
                        loadEntry(firstEntryIndex);  // Load the first entry by default if entries exist
                    } else {
                        console.error("No entries found:", data);
                    }
                } catch (error) {
                    console.error("Error fetching total entries:", error);
                }
            } else {
                console.error("User is not authenticated");
            }
        }
        fetchTotalEntries();
    }, []); // this useEffect block will only run once (because the dependency array is empty), but AFTER the first render

    const loadEntry = async (index) => {
        loading.current = true;
        // setLoading(true);
        // console.log("Loading entry:", index);
        if (auth.isAuthenticated) {
            try {
                const token = auth.user?.access_token;
                const response = await fetch(`${process.env.REACT_APP_API_URI}/farbenspiel/item/${index}`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const entryData = await response.json();
                setCurrentEntry(entryData);  // Update currentEntry to trigger re-render
                setDetailsVisible({});  // Reset visibility state
            } catch (error) {
                console.error("Error loading entry:", error);
                setCurrentEntry(null);  // Handle failed fetch
            } finally {
                loading.current = false;
                // setLoading(false);
            }
        } else {
            console.error("User is not authenticated");
            // loading.current = false; // not sure
        }
    };
    
    const handleNextEntry = () => {
        const nextEntryIndex = Math.floor(Math.random() * gameState.current.length);
        const nextEntry = gameState.current[nextEntryIndex];
        
        if (nextEntry === 0){
            console.error("entry 0 should not be possible!");
        }
    
        setProgress(100 - ((gameState.current.length - 1) / totalEntries * 100));

        // Remove the selected entry from the array
        gameState.current = gameState.current.filter((val, _) => val !== nextEntry);
        // console.log("Remaining gameState:", gameState.current);
    
        if (gameState.current.length === 0) {
        //   console.log("last entry displayed");
        //   setGameFinished(true);
          gameFinished.current = true;
        }
    
        // Load the next entry
        loadEntry(nextEntry);
    };

    const restartGame = () => {
        setProgress(0);
        setCurrentEntry(null);
        gameFinished.current = false;
        gameState.current = Array.from({ length: totalEntries }, (_, i) => i + 1);
        const firstEntryIndex = Math.floor(Math.random() * totalEntries);
        gameState.current = gameState.current.filter((val, _) => val !== firstEntryIndex);
        loadEntry(firstEntryIndex);
    }

    const hatFlagSceneWidthReference = 372;
    const hatFlagSceneHeightReference = 334;
    const bannerSceneWidthReference = 273;
    const bannerSceneHeightReference = hatFlagSceneHeightReference;
    
    const [hatFlagDimensions, setHatFlagDimensions] = useState({
        width: hatFlagSceneWidthReference,
        height: hatFlagSceneHeightReference,
    });
    const [bannerDimensions, setBannerDimensions] = useState({
        width: bannerSceneWidthReference,
        height: bannerSceneHeightReference,
    });

    // https://stackoverflow.com/a/61511955
    function waitForElement(selector) {
        return new Promise(resolve => {
            if (document.querySelector(selector)) {
                return resolve(document.querySelector(selector));
            }
    
            const observer = new MutationObserver(mutations => {
                if (document.querySelector(selector)) {
                    observer.disconnect();
                    resolve(document.querySelector(selector));
                }
            });
    
            // If you get "parameter 1 is not of type 'Node'" error, see https://stackoverflow.com/a/77855838/492336
            observer.observe(document.body, {
                childList: true,
                subtree: true
            });
        });
    }

    const fitStageIntoParentContainer = () => {
        const hatFlagContainerWidth = document.getElementById("hat-flag-burschen-canvas").offsetWidth;
        const bannerContainerWidth = document.getElementById("banner-canvas").offsetWidth;

        const hatFlagScale = hatFlagContainerWidth / hatFlagSceneWidthReference;
        const bannerScale = bannerContainerWidth / bannerSceneWidthReference;

        setHatFlagDimensions({
            width: hatFlagSceneWidthReference * hatFlagScale,
            height: hatFlagSceneHeightReference * hatFlagScale,
        });

        setBannerDimensions({
            width: bannerSceneWidthReference * bannerScale,
            height: hatFlagSceneHeightReference * hatFlagScale,
        });
    };

    
    useEffect(() => {
        // this is arguably a bit hacky... but it works
        waitForElement('#hat-flag-burschen-canvas').then(() => {
            waitForElement('#banner-canvas').then(() => {
                fitStageIntoParentContainer();
            });
        });
        
        window.addEventListener('resize', fitStageIntoParentContainer);
    
        return () => {
            window.removeEventListener('resize', fitStageIntoParentContainer);
        };
    }, []);


    const toggleVisibility = (field) => {
        setDetailsVisible(prev => ({
            ...prev,
            [field]: !prev[field],
        }));
    };

    const revealAllDetails = () => {
        const allFields = [
            'briefanredeKurz',
            'briefanrede',
            'verbindungsart',
            'verbindungsartLang',
            'name',
            'gruendungsdatum',
            'verband',
            'verbandLang',
            'burschenfarben',
            'fechtprinzip',
            'fuxenfarben',
            'perkussionBurschen',
            'geschlecht',
            'perkussionFuxen',
            'farbenart'
        ];

        const visibilityState = allFields.reduce((acc, field) => {
            acc[field] = true;
            return acc;
        }, {});

        setDetailsVisible(visibilityState);
    };

    // don't want Loading to show when I'm just hitting "next"
    // the !currentEntry ensures that Loading is just shown on the first load
    // in subsequent loads, it is alright if the previous entry is still displayed while the next one is loading
    if (!currentEntry && loading.current) {
        return <Loading/>;
    }

    const flag_colors_bursch = {
        perkussionObenCode: currentEntry.perkussionObenCode,
        perkussionUntenCode: currentEntry.perkussionUntenCode,
        farbe1Code: currentEntry.farbe1Code,
        farbe2Code: currentEntry.farbe2Code,
        farbe3Code: currentEntry.farbe3Code,
        grundCode: currentEntry.grundCode,
    }

    const flag_colors_fux = {
        perkussionObenCode: currentEntry.perkussionFuxObenCode,
        perkussionUntenCode: currentEntry.perkussionFuxUntenCode,
        farbe1Code: currentEntry.farbeFux1Code,
        farbe2Code: currentEntry.farbeFux2Code,
        farbe3Code: currentEntry.farbeFux3Code,
        grundCode: currentEntry.grundFuxCode,
    }

    const muetze_colors_bursch = {
        muetzeCode: currentEntry.muetzeCode,
        bordeCode: currentEntry.bordeCode,
        muetzefarbe1Code: currentEntry.muetzefarbe1Code,
        muetzefarbe2Code: currentEntry.muetzefarbe2Code,
        muetzefarbe3Code: currentEntry.muetzefarbe3Code,
    }

    const muetze_colors_fux = {
        muetzeCode: currentEntry.muetzeCode,
        bordeCode: currentEntry.bordeCode,
        muetzefarbe1Code: currentEntry.muetzeFuxfarbe1Code,
        muetzefarbe2Code: currentEntry.muetzeFuxfarbe2Code,
        muetzefarbe3Code: currentEntry.muetzeFuxfarbe3Code,
    }

    const colors_bursch = { ...muetze_colors_bursch, ...flag_colors_bursch };
    const colors_fux = { ...muetze_colors_fux, ...flag_colors_fux };

    // determine whether to draw the HatFlagCanvas by checking whether currentEntry.farbenart==='Tragend'
    const drawHatFlag = currentEntry.farbenart === 'Tragend';

    // concatenate farbe1 to farbe3 to get burschenfarben
    // if farbe3 is '-' then burschenfarben is just farbe1-farbe2
    let burschenfarben, fuxenfarben, perkussionBurschen, perkussionFuxen;
    if (currentEntry.farbe3 === '-') {
        burschenfarben = `${currentEntry.farbeFux1}-${currentEntry.farbeFux2}`;
    } else {
        burschenfarben = `${currentEntry.farbe1}-${currentEntry.farbe2}-${currentEntry.farbe3}`;
    }

    if (currentEntry.farbeFux3 === '-') {
        fuxenfarben = `${currentEntry.farbeFux1}-${currentEntry.farbeFux2}`;
    } else {
        fuxenfarben = `${currentEntry.farbeFux1}-${currentEntry.farbeFux2}-${currentEntry.farbeFux3}`;
    }

    if (currentEntry.perkussionOben === '-') {
        perkussionBurschen = '-';
    } else {
        perkussionBurschen = `${currentEntry.perkussionOben}-${currentEntry.perkussionUnten}`;
    }
    
    if (currentEntry.perkussionFuxOben === '-') {
        perkussionFuxen = '-';
    } else {
        perkussionFuxen = `${currentEntry.perkussionFuxOben}-${currentEntry.perkussionFuxUnten}`;
    }

    return (
        <Container maxWidth="lg" style={{ backgroundColor: '#efefef', padding: 2 }}>
            <Divider component="div" role="presentation" sx={{ marginY: 1 }}>
                <Typography sx={{ typography: "overline" }}>Informationsabfrage</Typography>
            </Divider>
            <Box sx={{ margin: 2 }}>
                <Grid container spacing={2}>
                    <Grid size={2}>
                        <Item onClick={() => toggleVisibility('briefanredeKurz')}>
                            <ItemTypography sx={{ fontWeight: detailsVisible['briefanredeKurz'] && currentEntry.briefanredeKurz ? 'bold' : 'normal' }}>
                                {detailsVisible['briefanredeKurz'] ? (currentEntry.briefanredeKurz || '-') : 'Briefanrede kurz'}
                            </ItemTypography>
                        </Item>
                    </Grid>
                    <Grid size={10}>
                        <Item onClick={() => toggleVisibility('briefanrede')}>
                            <ItemTypography sx={{ fontWeight: detailsVisible['briefanrede'] && currentEntry.briefanrede ? 'bold' : 'normal' }}>
                                {detailsVisible['briefanrede'] ? (currentEntry.briefanrede || '-') : 'Briefanrede ausgeschrieben'}
                            </ItemTypography>
                        </Item>
                    </Grid>
                    <Grid size={2}>
                        <Item onClick={() => toggleVisibility('verbindungsart')}>
                            <ItemTypography sx={{ fontWeight: detailsVisible['verbindungsart'] && currentEntry.anredeKurz ? 'bold' : 'normal' }}>
                                {detailsVisible['verbindungsart'] ? (currentEntry.anredeKurz || '-') : 'Verbindungsart'}
                            </ItemTypography>
                        </Item>
                    </Grid>
                    <Grid size={10}>
                        <Item onClick={() => toggleVisibility('verbindungsartLang')}>
                            <ItemTypography sx={{ fontWeight: detailsVisible['verbindungsartLang'] && currentEntry.anrede ? 'bold' : 'normal' }}>
                                {detailsVisible['verbindungsartLang'] ? (currentEntry.anrede || '-') : 'Verbindungsart ausgeschrieben'}
                            </ItemTypography>
                        </Item>
                    </Grid>
                    <Grid size={8}>
                    <Item sx={{
                            backgroundColor: "#e3d42e",
                            // color: theme => theme.palette.warning.contrastText
                        }} onClick={() => toggleVisibility('name')}>
                        <ItemTypography sx={{ fontWeight: detailsVisible['name'] && currentEntry.name ? 'bold' : 'normal' }}>
                            {detailsVisible['name'] ? (currentEntry.name || '-') : 'Name der Verbindung'}
                        </ItemTypography>
                    </Item>
                    </Grid>
                    <Grid size={4}>
                    <Item onClick={() => toggleVisibility('gruendungsdatum')}>
                        <ItemTypography sx={{ fontWeight: detailsVisible['gruendungsdatum'] && currentEntry.gruendungsdatum ? 'bold' : 'normal' }}>
                            {detailsVisible['gruendungsdatum'] ? (currentEntry.gruendungsdatum || '-') : 'Gründung'}
                        </ItemTypography>
                    </Item>
                    </Grid>
                    <Grid size={2}>
                    <Item onClick={() => toggleVisibility('verband')}>
                        <ItemTypography sx={{ fontWeight: detailsVisible['verband'] && currentEntry.dachverbandKurz ? 'bold' : 'normal' }}>
                            {detailsVisible['verband'] ? (currentEntry.dachverbandKurz || '-') : 'Verband'}
                        </ItemTypography>
                    </Item>
                    </Grid>
                    <Grid size={10}>
                    <Item onClick={() => toggleVisibility('verbandLang')}>
                        <ItemTypography sx={{ fontWeight: detailsVisible['verbandLang'] && currentEntry.dachverband ? 'bold' : 'normal' }}>
                            {detailsVisible['verbandLang'] ? (currentEntry.dachverband || '-') : 'Verband ausgeschrieben'}
                        </ItemTypography>
                    </Item>
                    </Grid>
                </Grid>
            </Box>
            <Divider component="div" role="presentation" sx={{ marginY: 0.5 }}>
                <Typography sx={{ typography: "overline" }}>Farbenabfrage</Typography>
            </Divider>
            <Box sx={{ margin: 0.5 }}>
                <Grid container spacing={3} justifyContent={"center"}>
                    <Grid size={4}>
                        <LabelItem><Typography>Burschenfarben</Typography></LabelItem>
                    </Grid>
                    <Grid size={3}>
                        <LabelItem><Typography>Fahne/Banner</Typography></LabelItem>
                    </Grid>
                    <Grid size={4}>
                        <LabelItem><Typography>Fuxenfarben</Typography></LabelItem>
                    </Grid>
                    <Grid size={4}>
                        <ImageItem id='burschen-canvas'>
                            <div id='hat-flag-burschen-canvas' style={{ width: '100%' }}>
                                <HatFlagCanvas colors={colors_bursch} dimensions={hatFlagDimensions} draw={drawHatFlag}/>
                            </div></ImageItem>
                    </Grid>
                    <Grid size={3}>
                        <ImageItem>
                            <div id='banner-canvas' style={{ width: '100%' }}>
                                <BannerCanvas colors={colors_bursch} dimensions={bannerDimensions} type={currentEntry.fahnenart}/>
                            </div></ImageItem>
                    </Grid>
                    <Grid size={4}>
                        <ImageItem id='fuxen-canvas'>
                            <div id='hat-flag-fuxen-canvas' style={{ width: '100%' }}>
                                <HatFlagCanvas colors={colors_fux} dimensions={hatFlagDimensions} draw={drawHatFlag}/>
                            </div></ImageItem>
                    </Grid>
                    <Grid size={4}>
                    <Item onClick={() => toggleVisibility('burschenfarben')}>
                        <ItemTypography sx={{ fontWeight: detailsVisible['burschenfarben'] && burschenfarben ? 'bold' : 'normal' }}>
                            {detailsVisible['burschenfarben'] ? burschenfarben : 'Burschenfarben'}
                        </ItemTypography>
                    </Item>
                    </Grid>
                    <Grid size={3}>
                    <Item onClick={() => toggleVisibility('fechtprinzip')}>
                        <ItemTypography sx={{ fontWeight: detailsVisible['fechtprinzip'] && currentEntry.partien ? 'bold' : 'normal' }}>
                            {detailsVisible['fechtprinzip'] ? (currentEntry.partien || '-') : 'Fechtprinzip'}
                        </ItemTypography>
                    </Item>
                    </Grid>
                    <Grid size={4}>
                    <Item onClick={() => toggleVisibility('fuxenfarben')}>
                        <ItemTypography sx={{ fontWeight: detailsVisible['fuxenfarben'] && fuxenfarben ? 'bold' : 'normal' }}>
                            {detailsVisible['fuxenfarben'] ? fuxenfarben : 'Fuxenfarben'}
                        </ItemTypography>
                    </Item>
                    </Grid>
                    <Grid size={4}>
                    <Item onClick={() => toggleVisibility('perkussionBurschen')}>
                        <ItemTypography sx={{ fontWeight: detailsVisible['perkussionBurschen'] && perkussionBurschen ? 'bold' : 'normal' }}>
                            {detailsVisible['perkussionBurschen'] ? perkussionBurschen : 'Perkussion'}
                        </ItemTypography>
                    </Item>
                    </Grid>
                    <Grid size={3}>
                    <Item onClick={() => toggleVisibility('geschlecht')}>
                        <ItemTypography sx={{ fontWeight: detailsVisible['geschlecht'] && currentEntry.geschlecht ? 'bold' : 'normal' }}>
                            {detailsVisible['geschlecht'] ? (currentEntry.geschlecht || '-') : 'Geschlecht'}
                        </ItemTypography>
                    </Item>
                    </Grid>
                    <Grid size={4}>
                    <Item onClick={() => toggleVisibility('perkussionFuxen')}>
                        <ItemTypography sx={{ fontWeight: detailsVisible['perkussionFuxen'] && perkussionFuxen ? 'bold' : 'normal' }}>
                            {detailsVisible['perkussionFuxen'] ? perkussionFuxen : 'Perkussion'}
                        </ItemTypography>
                    </Item>
                    </Grid>
                </Grid>
            </Box>
            <Divider component="div" role="presentation" sx={{ marginY: 0.5 }}>
                <Typography sx={{ typography: "overline" }}>Kontrolleiste</Typography>
            </Divider>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    margin: 0.5,
                    width: '100%', // or any width you prefer
                    marginBottom: 2
                }}
            >   
                <Box sx={{ width: '50%' }}> {/* Adjust the width as needed */}
                    <LinearProgressWithLabel value={progress} />
                </Box>
                <Box>
                    <ButtonGroup variant="contained" color="primary" aria-label="contained primary button group">
                        <Button onClick={gameFinished.current ? restartGame : handleNextEntry}
                                startIcon={gameFinished.current ? <RestartAltRoundedIcon/> : <NavigateNextRoundedIcon/>}>
                            {gameFinished.current ? 'Neustart' : 'Weiter'}
                        </Button>
                        <Button onClick={revealAllDetails} endIcon={<VisibilityRoundedIcon/>}>
                            Details anzeigen
                            </Button>
                    </ButtonGroup>
                </Box>
                <Box>
                    <Button variant="contained" color="primary" onClick={() => { auth.removeUser(); auth.signoutRedirect({ id_token_hint: auth.user?.id_token }); }}>Logout</Button>
                </Box>
            </Box>
            <div>
            <Snackbar open={gameFinished.current ? true : false} autoHideDuration={6000}>
                <Alert
                severity="success"
                variant="filled"
                sx={{ width: '100%' }}
                >
                Das ist der letzte Eintrag! Klicke auf "Neustart" um von vorne zu beginnen.
                </Alert>
            </Snackbar>
            </div>
        </Container>
    );
}

export default Farbenspiel;