import React from 'react';
import { Rect } from 'react-konva';


function FlagFrame ({ dims }) {
    return (
      <Rect
        stroke="black"
        x={0}
        y={0}
        width={dims.w}
        height={dims.h}
        strokeWidth={1}
      />
    );
  }

export default FlagFrame;