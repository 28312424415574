import React from 'react';
import { CircularProgress } from '@mui/material';
import CenteredContainer from './CenteredContainer'; // Make sure to import the updated component

function Loading() {
    return (
        <CenteredContainer>
            <CircularProgress/>
        </CenteredContainer>
    );
};

export default Loading;