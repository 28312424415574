import React from 'react';
import { Shape, Layer, Line } from 'react-konva';

// Function to scale points
function scalePoints(points, dims, originalDims) {
    return points.map((point, index) => {
      if (index % 2 === 0) { // x coordinate
        return (point / originalDims.width) * dims.w;
      } else { // y coordinate
        return (point / originalDims.height) * dims.h;
      }
    });
  }
  
  // Mützendeckel Component
  function Muetzendeckel({ color, dims }) {
    const originalPoints = [12, 14, 42, 8, 122, 8, 160, 16, 222, 34, 226, 40, 208, 74, 206, 96, 16, 72, 20, 52];
    const scaledPoints = scalePoints(originalPoints, dims, { width: 230, height: 110 });
  
    return (
      <Line
        points={scaledPoints}
        stroke="#000000"
        strokeWidth={1 * (dims.w / 230)}
        fill={color}
        closed
      />
    );
  }
  
  // Split Muetzenschirm Component
// Split Muetzenschirm Component
function Muetzenschirm({ dims }) {
    // First part: triangle
    const originalPoints = [16, 72, 2, 102, 72, 80];
    const scaledPoints = scalePoints(originalPoints, dims, { width: 230, height: 110 });
  
    // Second part: Bezier curve
    const startPoint = [2, 102];
    const controlPoint = [37, 98];
    const endPoint = [72, 80];
    const scaledStartPoint = scalePoints(startPoint, dims, { width: 230, height: 110 });
    const scaledControlPoint = scalePoints(controlPoint, dims, { width: 230, height: 110 });
    const scaledEndPoint = scalePoints(endPoint, dims, { width: 230, height: 110 });
  
    return (
      <>
        {/* First part: Triangle */}
        <Line
          points={scaledPoints}
          stroke="#000000"
          fill="#000000"
          closed
          strokeWidth={1 * (dims.w / 230)}
        />
  
        {/* Second part: Bezier Curve */}
        <Shape
          sceneFunc={(context, shape) => {
            context.beginPath();
            context.moveTo(scaledStartPoint[0], scaledStartPoint[1]);
            context.quadraticCurveTo(
              scaledControlPoint[0],
              scaledControlPoint[1],
              scaledEndPoint[0],
              scaledEndPoint[1]
            );
            // Draw a straight line back to the start point
            context.lineTo(scaledStartPoint[0], scaledStartPoint[1]);
            context.closePath();
            context.fill(); // Fill the shape
            context.stroke(); // Stroke the shape
          }}
          fill="#000000"
          stroke="#000000"
          strokeWidth={1 * (dims.w / 230)}
        />
      </>
    );
  }
  
  // Borde Component
  function Borde({ color, dims }) {
    const originalPoints = [12, 16, 226, 40];
    const scaledPoints = scalePoints(originalPoints, dims, { width: 230, height: 110 });
  
    return (
      <Line
        points={scaledPoints}
        stroke={color}
        strokeWidth={3 * (dims.w / 230)}
      />
    );
  }
  
  // Obere Farbe Component
  function ObereFarbe({ color, dims }) {
    const originalPoints = [17, 60, 208, 85];
    const scaledPoints = scalePoints(originalPoints, dims, { width: 230, height: 110 });
  
    return (
      <Line
        points={scaledPoints}
        stroke={color}
        strokeWidth={5 * (dims.w / 230)}
      />
    );
  }
  
  // Mittlere Farbe Component
  function MittlereFarbe({ color, dims }) {
    const originalPoints = [16, 65, 208, 90];
    const scaledPoints = scalePoints(originalPoints, dims, { width: 230, height: 110 });
  
    return (
      <Line
        points={scaledPoints}
        stroke={color}
        strokeWidth={5 * (dims.w / 230)}
      />
    );
  }
  
  // Untere Farbe Component
  function UntereFarbe({ color, dims }) {
    const originalPoints = [16, 70, 207, 95];
    const scaledPoints = scalePoints(originalPoints, dims, { width: 230, height: 110 });
  
    return (
      <Line
        points={scaledPoints}
        stroke={color}
        strokeWidth={5 * (dims.w / 230)}
      />
    );
  }
  
  // Combined Component
  function Hat({ colors, dims }) {
    return (
      <Layer
        x={dims.x}
        y={dims.y}
      >
        <Muetzendeckel color={colors.muetzeCode} dims={dims} />
        <Muetzenschirm dims={dims} />
        <Borde color={colors.bordeCode} dims={dims} />
        <ObereFarbe color={colors.muetzefarbe1Code} dims={dims} />
        <MittlereFarbe color={colors.muetzefarbe2Code} dims={dims} />
        <UntereFarbe color={colors.muetzefarbe3Code} dims={dims} />
      </Layer>
    );
  }

export default Hat;