import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from 'oidc-client-ts'; // automatically sign-in
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { blue, red } from '@mui/material/colors';
import CssBaseline from '@mui/material/CssBaseline';

const myOnSigninCallback = () => {
  window.history.replaceState(
    {},
    document.title,
    window.location.pathname
  )
}

const oidcConfig = {
  authority: process.env.REACT_APP_OIDC_SERVER,
  client_id: process.env.REACT_APP_OIDC_CLIENT,
  redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URI,
  onSigninCallback: myOnSigninCallback, // removes state params from url
  post_logout_redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URI, // prompt new login flow after logout
  userStore: new WebStorageStateStore({ store: window.localStorage }), // automatically sign-in
};


// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: "#00418B",  // CD blue
    },
    secondary: {
      main: "#8b0e2e",  // dark red used in Farbenspiel
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: "#00418B", // Default background color for all buttons
          color: '#fff',  // Text color
          '&:hover': {
            backgroundColor: "#0e3872", // Background color on hover
          },
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
        </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>
);