import React, { useRef, useEffect } from 'react';
import { Stage } from 'react-konva';
import Banner from './Banner';

function BannerCanvas({ colors, dimensions, type }) {
    const stageRef = useRef(null);

    const sceneWidth = 273;
    const sceneHeight = 334;

    let midLayerWidth, midLayerHeight, midLayerX, midLayerY;

    if (type === 'Banner') {
        midLayerWidth = (0.75) * sceneWidth;
        midLayerHeight = (195/235) * sceneHeight;
        midLayerX = (1/8) * sceneWidth;
        midLayerY = (4 * sceneHeight / 47);
    } else {
        midLayerWidth = (0.75) * sceneWidth;
        midLayerHeight = (85/235) * sceneHeight;
        midLayerX = (1/8) * sceneWidth;
        midLayerY = (15 * sceneHeight / 47);
    }

    useEffect(() => {
        if (stageRef.current) {
            const scale = dimensions.width / sceneWidth; // Scale based on the initial width of 273
            stageRef.current.scale({ x: scale, y: scale });
        }
    }, [dimensions]);

    return (
        <Stage ref={stageRef} width={dimensions.width} height={dimensions.height}>
            <Banner colors={colors} dims={{ x: midLayerX, y: midLayerY, w: midLayerWidth, h: midLayerHeight }} type={type} />
        </Stage>
    );
}

export default BannerCanvas;