import React,  {useEffect, useState } from "react";
import { useAuth, hasAuthParams } from "react-oidc-context";
// import Login from "./Login"
import Loading from "./Loading"
import AuthError from "./AuthError";
import Farbenspiel from "./Farbenspiel";

export default function App() {
    useEffect(() => {
        document.title = 'ABT Bundesorga'
    }, []);

    const auth = useAuth();
    const [hasTriedSignin, setHasTriedSignin] = useState(false);

    // automatically sign-in
    useEffect(() => {
        if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading && !hasTriedSignin) {
            auth.signinRedirect();
            setHasTriedSignin(true);
        }
    }, [auth, hasTriedSignin]);

    switch (auth.activeNavigator) {
        case "signinSilent":
            return <Loading/>;
        case "signoutRedirect":
            return <Loading/>;
    }

    if (auth.isLoading) {
        return <Loading/>;
    }

    if (auth.error) {
        return <AuthError/>;
    }

    if (auth.isAuthenticated) {
        return <Farbenspiel/>;
    } else {
        return <Loading/>;
    }

}