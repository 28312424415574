import React from "react";
import { Layer, Rect } from "react-konva";
import FlagFrame from "./FlagFrame";

function BannerHorizontal ({ colors, dims }) {
    return (
        <Layer
        x={dims.x}
        y={dims.y}
        >
        {/* Farbe1 */}
        <Rect x={0} y={0} width={dims.w} height={(1/3)*dims.h} fill={colors.farbe1Code} />
  
        {/* Farbe2 */}
        <Rect x={0} y={(1/3)*dims.h} width={dims.w} height={(1/3)*dims.h} fill={colors.farbe2Code} />

        {/* Farbe3 */}
        <Rect x={0} y={(2/3)*dims.h} width={dims.w} height={(1/3)*dims.h} fill={colors.farbe3Code} />

        {/* Rahmen */}
        <FlagFrame dims={dims} />
    </Layer>
    );
}

function BannerVertical ({ colors, dims }) {
    return (
        <Layer
        x={dims.x}
        y={dims.y}
        >
        {/* Farbe1 */}
        <Rect x={0} y={0} width={(1/3)*dims.w} height={dims.h} fill={colors.farbe1Code} />
  
        {/* Farbe2 */}
        <Rect x={(1/3)*dims.w} y={0} width={(1/3)*dims.w} height={dims.h} fill={colors.farbe2Code} />

        {/* Farbe3 */}
        <Rect x={(2/3)*dims.w} y={0} width={(1/3)*dims.w} height={dims.h} fill={colors.farbe3Code} />

        {/* Rahmen */}
        <FlagFrame dims={dims} />
    </Layer>
    );
}

function Banner({ colors, dims, type}){
    if (type === 'Banner') {
        return <BannerVertical colors={colors} dims={dims} />;
    } else if (type === 'FahneKopf') {
        const swappedColors = {
            ...colors,
            farbe1Code: colors.farbe3Code,
            farbe3Code: colors.farbe1Code,
        };
        return <BannerHorizontal colors={swappedColors} dims={dims} />;
    } else {
        return <BannerHorizontal colors={colors} dims={dims} />;
    }
}

export default Banner;