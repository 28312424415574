import React from 'react';
import { Alert, AlertTitle, Button, Paper, Typography } from '@mui/material';
import { useAuth } from 'react-oidc-context';
import CenteredContainer from './CenteredContainer'; // Make sure to import the updated component

function AuthError() {
    const auth = useAuth();
    return (
        <CenteredContainer>
            <Paper
                elevation={5}
                style={{
                    padding: '2rem', // Reduced padding for better proportion
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 'auto',
                    maxWidth: '500px', // Limit the max width for readability
                    borderRadius: '8px',
                }}
            >
                <Alert severity="error" style={{ marginBottom: '1rem' }}>
                    <AlertTitle>Anmeldung fehlgeschlagen!</AlertTitle>
                    <Typography variant="body1">
                        Grund: {auth.error.message} 
                        {/* z.B.: Token is not active */}
                    </Typography>
                </Alert>
                {/* add a button to redirect to login */}
                <Button variant="contained" color="primary" size="large" onClick={() => void auth.signinRedirect()}>
                Login mit ABT-SSO
                </Button>
                </Paper>
        </CenteredContainer>
    );
}

export default AuthError;