import React, { useRef, useEffect } from 'react';
import { Stage } from 'react-konva';

import Flag from './Flag';
import Hat from './Hat';

function HatFlagCanvas({ colors, dimensions, draw }) {
  const stageRef = useRef(null);

  const sceneWidth = 372;
  const sceneHeight = 334;
  const topLayerWidth = 0.85 * sceneWidth;
  const topLayerHeight = (11/23) * sceneWidth;
  const topLayerX = 0.075 * sceneWidth;
  const topLayerY = (1 * sceneHeight / 25);
  const bottomLayerWidth = (15 * sceneWidth / 27);
  const bottomLayerHeight = (15 * 17 * sceneWidth / (27 * 30));
  const bottomLayerX = (1 - (15 / 27)) * 0.5 * sceneWidth;
  const bottomLayerY = sceneHeight - 2*topLayerY - bottomLayerHeight;

  useEffect(() => {
    if (stageRef.current) {
      const scale = dimensions.width / sceneWidth;
      stageRef.current.scale({ x: scale, y: scale });
    }
  }, [dimensions]);


  if (draw) {
    return (
      <Stage ref={stageRef} width={dimensions.width} height={dimensions.height}>
        <Hat colors={colors} dims={{ x: topLayerX, y: topLayerY, w: topLayerWidth, h: topLayerHeight }} />
        <Flag colors={colors} dims={{ x: bottomLayerX, y: bottomLayerY, w: bottomLayerWidth, h: bottomLayerHeight }} />
      </Stage>
    );
  } else {
    return (
      <Stage ref={stageRef} width={dimensions.width} height={dimensions.height}>
      </Stage>
    )
  }
};

export default HatFlagCanvas;
