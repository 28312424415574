import React from 'react';
import { Layer, Rect } from 'react-konva';
import FlagFrame from './FlagFrame';
  
function FlagNoGrundThreeStripes ({ colors, dims }) {
  return (
    <Layer
    x={dims.x}
    y={dims.y}
    >
    {/* Perkussion Oben */}
    <Rect x={0} y={0} width={dims.w} height={(5/85)*dims.h} fill={colors.perkussionObenCode} />

    {/* Farbe1 */}
    <Rect x={0} y={(5/85)*dims.h} width={dims.w} height={(25/85)*dims.h} fill={colors.farbe1Code} />

    {/* Farbe2 */}
    <Rect x={0} y={(30/85)*dims.h} width={dims.w} height={(25/85)*dims.h} fill={colors.farbe2Code} />

    {/* Farbe3 */}
    <Rect x={0} y={(55/85)*dims.h} width={dims.w} height={(25/85)*dims.h} fill={colors.farbe3Code} />

    {/* Perkussion Unten */}
    <Rect x={0} y={(80/85)*dims.h} width={dims.w} height={(5/85)*dims.h} fill={colors.perkussionUntenCode} />

    {/* Frame */}
    <FlagFrame dims={dims} />
    </Layer>
  );
}

function FlagNoGrundTwoStripes ({ colors, dims }) {
  return (
    <Layer
    x={dims.x}
    y={dims.y}
    >
    {/* Perkussion Oben */}
    <Rect x={0} y={0} width={dims.w} height={(5/85)*dims.h} fill={colors.perkussionObenCode} />

    {/* Farbe1 */}
    <Rect x={0} y={(5/85)*dims.h} width={dims.w} height={(37.5/85)*dims.h} fill={colors.farbe1Code} />

    {/* Farbe2 */}
    <Rect x={0} y={(42.5/85)*dims.h} width={dims.w} height={(37.5/85)*dims.h} fill={colors.farbe2Code} />

    {/* Perkussion Unten */}
    <Rect x={0} y={(80/85)*dims.h} width={dims.w} height={(5/85)*dims.h} fill={colors.perkussionUntenCode} />

    {/* Frame */}
    <FlagFrame dims={dims} />
    </Layer>
  );
}
  
function FlagWithGrund ({ colors, dims }) {
  return (
    <Layer
    x={dims.x}
    y={dims.y}
    >
    {/* Perkussion Oben */}
    <Rect x={0} y={0} width={dims.w} height={(5/85)*dims.h} fill={colors.perkussionObenCode} />

    {/* Grund Oben */}
    <Rect x={0} y={(5/85)*dims.h} width={dims.w} height={(6/85)*dims.h} fill={colors.grundCode} />

    {/* Farbe1 */}
    <Rect x={0} y={(11/85)*dims.h} width={dims.w} height={(21/85)*dims.h} fill={colors.farbe1Code} />

    {/* Farbe2 */}
    <Rect x={0} y={(32/85)*dims.h} width={dims.w} height={(21/85)*dims.h} fill={colors.farbe2Code} />

    {/* Farbe3 */}
    <Rect x={0} y={(53/85)*dims.h} width={dims.w} height={(21/85)*dims.h} fill={colors.farbe3Code} />

    {/* Grund Unten */}
    <Rect x={0} y={(74/85)*dims.h} width={dims.w} height={(6/85)*dims.h} fill={colors.grundCode} />

    {/* Perkussion Unten */}
    <Rect x={0} y={(80/85)*dims.h} width={dims.w} height={(5/85)*dims.h} fill={colors.perkussionUntenCode} />

    {/* Frame */}
    <FlagFrame dims={dims} />
    </Layer>
  );
}


function Flag({ colors, dims }) {
  if (colors.grundCode === '-') {
    if (colors.farbe3Code === '-') {
      return <FlagNoGrundTwoStripes colors={colors} dims={dims} />;
    } else {
      return <FlagNoGrundThreeStripes colors={colors} dims={dims} />;
    }
  } else {
    return <FlagWithGrund colors={colors} dims={dims} />;
  }
}

export default Flag;